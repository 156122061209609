import { load_checkbox_state, load_dropdown_state, load_textbox_state } from "./state_manager";
import { parse_validator } from "./validator";
import { DependencyGraph } from "./dependency_graph";
// This should be a more type safe reimplementation of 10_parse_data.js.
// It has some breaking changes, since I try to improve how the javascript code works
export const assert_field_type = (name, expected_type_str, parent_object) => {
    const value = parent_object[name];
    const actual_type_str = typeof (value);
    if (actual_type_str != expected_type_str) {
        throw new Error(`Type mismatch: ${name} should be ${expected_type_str}, but is ${actual_type_str}.\nProblematic object: ${JSON.stringify(parent_object)}`);
    }
    else {
        return value;
    }
};
// These functions are here to make sure, that I the type checker can properly work (since they have a specific return type)
export const get_string_field = (name, parent_object) => {
    return assert_field_type(name, "string", parent_object);
};
export const get_boolean_field = (name, parent_object) => {
    return assert_field_type(name, "boolean", parent_object);
};
const get_number_field = (name, parent_object) => {
    return assert_field_type(name, "number", parent_object);
};
export const get_array_field = (name, element_type, parent_object) => {
    const array = parent_object[name];
    if (Array.isArray(array)) {
        for (const [index, entry] of array.entries()) {
            const actual_type_str = typeof (entry);
            if (actual_type_str != element_type) {
                const msg = `Type mismatch: ${name}'s ${index + 1}th element should be ${element_type}, but is ${actual_type_str}.\nProblematic object: ${JSON.stringify(parent_object)}`;
                throw new Error(msg);
            }
        }
        return array;
    }
    else {
        throw new Error(`Type mismatch: ${name} should be an array, but is not.\nProblematic object: ${JSON.stringify(parent_object)}`);
    }
};
export var InputType;
(function (InputType) {
    InputType["Textbox"] = "TEXTBOX";
    InputType["Checkbox"] = "CHECKBOX";
    InputType["Dropdown"] = "DROPDOWN";
})(InputType || (InputType = {}));
export const parse_config = (data) => {
    const placeholder_map = new Map();
    const textboxes = new Map();
    const checkboxes = new Map();
    const dropdowns = new Map();
    const validator_map = new Map();
    const validator_data_list = get_array_field("validators", "object", data);
    for (const validator_data of validator_data_list) {
        const validator = parse_validator(validator_data);
        if (validator_map.has(validator.id)) {
            throw new Error(`Multiple validators with id '${validator.id}'`);
        }
        else {
            validator_map.set(validator.id, validator);
        }
    }
    const settings_data = assert_field_type("settings", "object", data);
    const settings = parse_settings(settings_data);
    const placeholder_data = get_array_field("placeholder_list", "object", data);
    for (let i = 0; i < placeholder_data.length; i++) {
        const placeholder = parse_any_placeholder(placeholder_data[i], validator_map, settings, i);
        // Add the placeholder to the correct lists
        placeholder_map.set(placeholder.name, placeholder);
        if (placeholder.type == InputType.Textbox) {
            textboxes.set(placeholder.name, placeholder);
        }
        else if (placeholder.type == InputType.Checkbox) {
            checkboxes.set(placeholder.name, placeholder);
        }
        else if (placeholder.type == InputType.Dropdown) {
            dropdowns.set(placeholder.name, placeholder);
        }
        else {
            console.warn("Unknown placeholder type:", placeholder.type);
        }
    }
    const graph = new DependencyGraph(placeholder_map);
    return {
        "placeholders": placeholder_map,
        "textboxes": textboxes,
        "checkboxes": checkboxes,
        "dropdowns": dropdowns,
        "settings": settings,
        "dependency_graph": graph,
        "input_tables": [],
    };
};
const parse_settings = (data) => {
    return {
        "debug": get_boolean_field("debug", data),
        "delay_millis": get_number_field("delay_millis", data),
        // @TODO: If I let users specify prefixes, I will need to make sure, that they do not contain regex characters or escape them
        // How normal placeholders are marked
        "normal_prefix": "x",
        "normal_suffix": "x",
        // How placeholders using the innerHTML method are marked
        "html_prefix": "i",
        "html_suffix": "i",
        // How placeholders using the direct/static replacement methodare marked
        "static_prefix": "s",
        "static_suffix": "s",
        // How placeholders using the dynamic replacement methodare marked
        "dynamic_prefix": "d",
        "dynamic_suffix": "d",
    };
};
const parse_any_placeholder = (data, validator_map, settings, index) => {
    const type = get_string_field("type", data);
    // Parse fields that are shared between all placeholders
    const name = get_string_field("name", data);
    let parsed = {
        "name": name,
        "order_index": index,
        // The regexes for the different replace methods. Stored here so that I only need to compile them once
        "regex_dynamic": RegExp(settings.dynamic_prefix + name + settings.dynamic_suffix, "g"),
        "regex_html": RegExp(settings.html_prefix + name + settings.html_suffix, "g"),
        "regex_normal": RegExp(settings.normal_prefix + name + settings.normal_suffix, "g"),
        "regex_static": RegExp(settings.static_prefix + name + settings.static_suffix, "g"),
        // 
        "description": get_string_field("description", data),
        "read_only": get_boolean_field("read_only", data),
        "allow_inner_html": get_boolean_field("allow_inner_html", data),
        "allow_recursive": false,
        "current_value": "UNINITIALIZED",
        "expanded_value": "UNINITIALIZED",
        "count_on_page": 0,
        "reload_page_on_change": false,
        "output_elements": [], // Will be set, when the page is searched
    };
    // Parse the type specific attributes
    if (type === "textbox") {
        const placeholder = finish_parse_textbox(parsed, data, validator_map);
        load_textbox_state(placeholder);
        return placeholder;
    }
    else if (type == "checkbox") {
        const placeholder = finish_parse_checkbox(parsed, data);
        load_checkbox_state(placeholder);
        return placeholder;
    }
    else if (type == "dropdown") {
        const placeholder = finish_parse_dropdown(parsed, data);
        load_dropdown_state(placeholder);
        return placeholder;
    }
    else {
        throw new Error(`Unsupported placeholder type '${type}'`);
    }
};
const finish_parse_textbox = (parsed, data, validator_map) => {
    let default_function, default_value;
    if (data["default_value"] != undefined) {
        default_value = get_string_field("default_value", data);
    }
    else {
        const default_js_code = get_string_field("default_function", data);
        default_function = () => {
            // Wrap the function, so that we can ensure that errors are properly handled
            try {
                const compiled_function = new Function(default_js_code);
                const result = compiled_function();
                if (typeof (result) != "string") {
                    throw new Error(`Custom function '${default_js_code}' should return a string, but it returned a ${typeof (result)}: ${result}`);
                }
                else {
                    return result;
                }
            }
            catch (error) {
                throw new Error(`Failed to evaluate default_function '${default_js_code}' of placeholder ${parsed.name}: ${error}`);
            }
        };
    }
    const validator_names = get_array_field("validators", "string", data);
    const validator_list = [];
    for (const name of validator_names) {
        const validator = validator_map.get(name);
        if (validator) {
            validator_list.push(validator);
        }
        else {
            const known_validators = Array.from(validator_map.keys()).join(", ");
            throw new Error(`No validator with id '${name}' was found. Known validators are ${known_validators}`);
        }
    }
    return Object.assign(Object.assign({}, parsed), { "allow_recursive": get_boolean_field("allow_recursive", data), "default_function": default_function, "default_value": default_value, "input_elements": [], "type": InputType.Textbox, "validators": validator_list });
};
const finish_parse_checkbox = (parsed, data) => {
    return Object.assign(Object.assign({}, parsed), { "allow_recursive": true, "checked_by_default": get_boolean_field("checked_by_default", data), "current_is_checked": false, "input_elements": [], "value_checked": get_string_field("value_checked", data), "value_unchecked": get_string_field("value_unchecked", data), "type": InputType.Checkbox });
};
const finish_parse_dropdown = (parsed, data) => {
    const raw_options = get_array_field("options", "object", data);
    const options = [];
    for (const option of raw_options) {
        options.push({
            display_name: get_string_field("display_name", option),
            value: get_string_field("value", option),
        });
    }
    const default_index = get_number_field("default_index", data);
    if (default_index < 0) {
        throw new Error(`Invalid value: "default_index" should not be negative, but is ${default_index}.\nProblematic object: ${JSON.stringify(data)}`);
    }
    else if (default_index >= options.length) {
        throw new Error(`Invalid value: "default_index" should be smaller than the number of options (${options.length}), but is ${default_index}.\nProblematic object: ${JSON.stringify(data)}`);
    }
    return Object.assign(Object.assign({}, parsed), { "allow_recursive": true, "current_index": 0, "default_index": default_index, "input_elements": [], "options": options, "type": InputType.Dropdown });
};
